import React, { ReactNode } from 'react';
import styled from 'styled-components/macro';
import { Typography, Paper, withTheme } from '@material-ui/core';

const Container = withTheme(styled(Paper)`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    padding: 2em;
    cursor: default;

    background: ${props => props.theme.palette.grey[100]};

`);

interface Props {
    text?: string | ReactNode;
    onClick?: () => void;
}

export const EmptyState = (props: Props) => {
    return <Container elevation={0} onClick={props.onClick}>
        <Typography variant="body1">{props.text || "..."}</Typography>
    </Container>;
}