import React, { useState, useEffect, createContext, ComponentPropsWithoutRef, useContext, ReactNode } from 'react';
import { apiFetch } from '../api/core';

export enum FieldType {
    bool = "bool",
    select = "select",
    dictionarySelect = "dictionary-select",
    date = "date",
    datetime = "datetime",
    number = "number",
    decimal = "decimal",
    text = "text",
    textlong = "text-long",
    markdown = "markdown",
}

export interface FieldSchema {
    type: FieldType;
    label?: string | ReactNode;
    values?: { value: any, label: string }[];
    valueDict?: any;
    dictionary?: string;
    ["max-length"]?: number;
    ["min-length"]?: number;
}

export interface Schema {
    [key: string]: FieldSchema;
};

export interface Schemas {
    case: Schema;
    arbitrator: Schema;
    casearbitrator: Schema;
    dictionary: Schema;
}


const addValuesDict = (fieldSchema: FieldSchema) => ({
    ...fieldSchema,
    valueDict: (fieldSchema.values || []).reduce((result, { value, label }) => ({ ...result, [value]: label }), {})})

const addValuesDictsToFields = (s: Schema) => Object.keys(s).reduce((result, f) => ({ ...result, [f]: addValuesDict(s[f])}), {}) as Schema;

const preprocessSchemas = (schemas: Schemas) => Object.keys(schemas).reduce((result, k) =>
    ({ ...result, [k]: addValuesDictsToFields((schemas as unknown as {[_: string]: Schema})[k])}), {}) as Schemas;

const loadSchemas = () => apiFetch<Schemas>(`/api/uiconfig`).then(preprocessSchemas);



const NotLoadedSchema: Schema = { NOTLOADED: { type: FieldType.text, valueDict: {}}};
const defaultSchemas = {
    case: NotLoadedSchema,
    arbitrator: NotLoadedSchema,
    casearbitrator: NotLoadedSchema,
    dictionary: NotLoadedSchema,
};

export const SchemaContext = createContext<Schemas>(defaultSchemas);

export const SchemaProvider = (props: ComponentPropsWithoutRef<any>) => {
    const [schemas, setSchemas] = useState<Schemas>(defaultSchemas);
    const [isAwaitingSchema, setIsAwaitingSchema] = useState<boolean>(false);
    const clearAwaiting = () => setIsAwaitingSchema(false);
    const setAwaiting = () => {
        setIsAwaitingSchema(false);
        setTimeout(clearAwaiting, 1000);
    }

    useEffect(() => {
        loadSchemas().then(setSchemas).then(clearAwaiting);
    }, []);

    const schemaAccessGuard = {
        get: (target: Schemas, name: string) => {
            const result = (target as unknown as Record<string, Schema>)[name];
            if(result === NotLoadedSchema) {
                console.log(`Schema ${name} not loaded yet, waiting...`);
                setAwaiting();
                return new Proxy(result, {
                    get: (t: Schema, f: string) => ({ type: FieldType.text, label: "", valueDict: {} })
                })
            } else {
                return result;
            }
        }
    };

    const guardedSchemas = new Proxy(schemas, schemaAccessGuard);

    return (isAwaitingSchema ?
        <>Loading schema...</> :
        <SchemaContext.Provider value={guardedSchemas}>
            {props.children}
        </SchemaContext.Provider>);
}

export const useSchema = () => useContext(SchemaContext);
