export const processTsv = (tabletype: string, text: string): string => {
    const t = tabletype || "md";

    const [header,...lines] = text.split("\n").map(l => l.replace(/\n|\r/, "")).filter(l => l.trim().length > 0);
    
    if(t === "html") {
        const toRow = (cellTag: string, line: string) => "<tr>" + line.split("\t").map(cell => `<${cellTag}>${cell}</${cellTag}>`).join("") + "</tr>";
        
        return [
            "<table>",
            "<thead>",
            toRow("th", header),
            "</thead>",
            "<tbody>",
            ...lines.map(l => toRow("td", l)),
            "</tbody>",
            "</table>",
        ].join("\n");
    } else {
        const toRow = (line: string) => line.split("\t").map(cell => `| ${cell} `).join("") + "|";
    
        return [
            toRow(header),
            header.split("\t").map(c => "|---").join("")+"|",
            ...lines.map(toRow),
        ].join("\n");
    }
}
