export const enMessages = {
    error: {
        general: "Something bad happened",
    },
    success: {
        clipboard: "Value copied to clipboard"
    },
    login: {
        email: "Email",
        password: "Password",
        submit: "Login"
    },
    signup: {
        errorMessage: "Something bad happened",
        successMessage: "The signup request has been sent. You will get access, once the administrator approves it",
        email: "Email",
        password: "Password",
        passwordRepeat: "Repeat password",
        submit: "Sign up"
    },
    signupConfirmation: {
        defaultMessage: "Confirming email...",
        successMessage: "Email confirmed successfully. Thank you!",
        errorMessage: "Something went wrong. Please get in touch with us."
    },
    resetPassword: {
        email: "Email",
        submit: "Reset password"
    },
    restorePassword: {
        password: "Password",
        passwordRepeat: "Repeat password",
        submit: "Change password"
    },
    searchModal: {
        title: "Search",
        searchFieldLabel: "Find...",
        pickItem: "Pick",
        emptyState: "Nothing has been found"
    },
    userManagement: {
        title: "User Management",
        header: {
            addUser: "Add user",
            sendInvites: "Send invites",
            sendInvintesTip: "Invites will be sent only to new users, no repeats"
        },
        columns: {
            email: "Email",
            lastLogin: "Last Login",
            invited: "Invited",
            confirmed: "Confirmed",
            invite: "Invite",
            signInAs: "Sign in"
        },
        userPopup: {
            title: "Add users",
            email: "Email",
            submit: "Create",
            hint: "enter emails of the users to be created, separated with whitespaces or semicolons"
        }
    },
    emails: {
        popup: {
            title: "Info",
            testEmail: "Send a test email to myself",
            testEmailTextTip: "Optionally use the field below to enter email parameters in JSON format",
            send: "Send",
            delete: "Delete",
            cancel: "Cancel",
            save: "Save"
        }
    },
    dictionaries: {
        title: "Dictionaries",
        statusSaved: "Saved",
        newRecordLabel: "New record code",
        addRecord: "Add",
        selectDictionary: "Dictionary"
    },
    queryconsole: {
        title: "Query title",
        query: "Query",
        run: "Run",
        history: "History",
    },
    common: {
        cancel: "Cancel"
    },
    formcontrol: {
        markdown: {
            write: 'Edit',
            preview: 'Preview',
        }
    },
    doxctemplater: {
        form: {
            fileNbr: 'File number*',
            filePeriod: 'File period*',
            INVNUMBER: "INVNUMBER*",
            DATE: "Date",
            PERIOD: "Period*",
            LINES: 'Lines',
            LINES_PRE: {
                title: 'Name of the work',
                name: 'Name',
                num: 'Count',
                units: 'units',
                price: 'Price',
                total: 'Total',
                remove: 'Remove line',
                add: 'Add line',
            },
            LINES_NBR: "Lines number*",
            PRICE: "Price*",
            TOTAL: "Total*",
            TOTAL_WORDS: "Total world*",
            
            QTY_D: "QTY D*",
            DETAILS_PRE: {
                title: 'Descript of the work',
                date: 'Date',
                descript: 'Descript',
                time: 'Time, h'
            },
            DETAILS: "Details",
            button: 'Select template and generate'
        }
    }
}