import React from 'react';
import { ThemeProvider, StylesProvider } from '@material-ui/core/styles';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import { SnackbarProvider } from 'notistack';
import ErrorBoundary from './toolympus/components/app/ErrorBoundary';
import { theme } from './theme';
import MomentUtils from '@date-io/moment';
import {
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import LocalizationProvider from './toolympus/components/localization/LocalizationProvider';
import { FormatsProvider } from './toolympus/components/schemed';
import { MainPage } from './components/MainPage/MainPage';
import { TextProcessorPage } from './components/TextProcessor/TextProcessorPage';

const App: React.FC = () => {
    return (
        <ThemeProvider theme={theme}>
            <StylesProvider injectFirst >
                <LocalizationProvider locale="ru" defaultLocale="ru">
                    <FormatsProvider>
                        <Router>
                            <SnackbarProvider maxSnack={3} anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
                                <ErrorBoundary>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <Switch>
                                            <Route path='/processor' component={TextProcessorPage} />
                                            <Route path='/' component={MainPage} />
                                        </Switch>
                                    </MuiPickersUtilsProvider>
                                </ErrorBoundary>
                            </SnackbarProvider>
                        </Router>
                    </FormatsProvider>
                </LocalizationProvider>
            </StylesProvider>
        </ThemeProvider>
    );
}

export default App;
