import { Button, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, TextField } from '@material-ui/core';
import { Add, Delete } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { callOpenFileUploader } from '../../toolympus/api/files';
import { Container, Field } from './DocxTemplater.style';
import { ParamComponent } from './ParamComponent';
import { generateDocument, loadParamsAndValue } from './templaterData';

type Params = { [key: string]: any };



const getItem = (name: string) => JSON.parse(localStorage.getItem(name) || "{}");

interface LoadParamsPopupProps {
    onClose: () => void;
    onSelect: (params: Params, value: any) => void;
}


export const LoadParamsPopup = ({onClose, onSelect}: LoadParamsPopupProps) => {
    const paramsObj = getItem('params');
    const valuesObj = getItem('values');

    const list = Object.keys(paramsObj);

    const select = (key: string) => () => {
        onClose();
        onSelect(paramsObj[key], valuesObj[key]);
    } 

    const clear = () => {
        onClose();
        localStorage.setItem('params', "{}");
        localStorage.setItem('values', "{}");
    }

    return <Dialog fullWidth maxWidth="sm" open={true} onClose={onClose}>
        {list.length !== 0? <>
                <DialogContent>
                    <List>
                        {list.map(title => <ListItem key={title} onClick={select(title)} button>{title}</ListItem>)}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={clear}>Clear</Button>
                </DialogActions>
            </>
            :
            <DialogContent>No saved params</DialogContent>
        }
    </Dialog>
}

export const DocxTemplater = () => {
    const [openSaveParams, setOpenSaveParams] = useState(false);
    const [openLoadParams, setOpenLoadParams] = useState(false);
    const [saveParamsTitle, setSaveParamsTitle] = useState('');
    const { enqueueSnackbar } = useSnackbar();
    const [files, setFiles] = useState<File[]>([]);
    const [params, setParams] = useState<Params>({});
    const [value, setValue] = useState<any>({});

    const templateError = () => enqueueSnackbar('There are some errors in your template. Please check whether all your parameters are named appropriately and all loop parameter blocks are closed', { variant: 'error' })

    const selectFile = (file: File) => {
        loadParamsAndValue(file, 
            (params, value) => {
                setParams(params);
                setValue(value);
                setFiles([file]);
            }, templateError);
    }

    const addTemplate = (file: File) => {
        loadParamsAndValue(file, (loadedParams, loadedValue) => {
            const newParams = params;
            const newValue = value;

            for (const key in loadedParams) {
                if (!Object.prototype.hasOwnProperty.call(loadedParams, key)) continue;

                if (!params[key]) {
                    newParams[key] = loadedParams[key];
                    newValue[key] = loadedValue[key];

                } else if (Object.keys(loadedParams[key]).length === Object.keys(params[key]).length) {
                    newParams[key] = params[key];
                    newValue[key] = value[key];

                } else {
                    enqueueSnackbar('The types of parameter ИМЯ do not match in two templates. Please process these templates separately or rename the parameter in one of them', { variant: 'error' });
                    return;
                }
            }

            setParams(newParams);
            setValue(newValue);
            setFiles([...files, file]);
        }, templateError);
    }

    const generateDocuments = () => {
        for (const file of files) {
            generateDocument(file, value);
        }
    }

    const saveParams = () => {
        const savedParams = getItem('params');
        const savedValues = getItem('values');

        savedParams[saveParamsTitle] = params;
        savedValues[saveParamsTitle] = value;

        localStorage.setItem('params', JSON.stringify(savedParams));
        localStorage.setItem('values', JSON.stringify(savedValues));
        setOpenSaveParams(false);
    }

    const loadParams = (params: Params, value: any) => {
        setParams(params);
        setValue(value);
    }

    return (
        <Container>
            {files.length === 0? 
                <Button variant="contained" color="primary" onClick={callOpenFileUploader(selectFile)}>Upload File</Button>
                :
                <>
                    Loaded templates:
                    <List>
                        {files.map(file => <ListItem>{file.name}</ListItem>)}
                    </List>
                    <div>
                        <Button color="primary" onClick={() => setOpenSaveParams(true)}>Save params</Button>
                        <Button color="primary" onClick={() => setOpenLoadParams(true)}>Load params</Button>
                    </div>
                    <Button startIcon={<Add />} onClick={callOpenFileUploader(addTemplate)}>Add another template</Button>
                    {Object.keys(params).map(key => 
                        <Field key={key}>
                            <ParamComponent 
                                data={{
                                    param: params[key],
                                    title: key,
                                    value: value[key],
                                    setValue: (paramValue) => setValue({...value, [key]: paramValue})
                                }} 
                            />
                        </Field>
                    )}
                    
                    <Button startIcon={<Delete />} onClick={callOpenFileUploader(selectFile)}>Clear all and create another document</Button>
                    <Button variant="contained" color="primary" onClick={generateDocuments}>Generate document</Button>
                </>
            }
            <Dialog open={openSaveParams} onClose={() => setOpenSaveParams(false)}>
                <DialogTitle>Enter a title that will help you remember what this set of parameters is for</DialogTitle>
                <DialogContent>
                    <TextField fullWidth label="Title" value={saveParamsTitle} onChange={e => setSaveParamsTitle(e.target.value)} />
                </DialogContent>
                <DialogActions>
                    <Button disabled={!saveParamsTitle} onClick={saveParams}>Save</Button>
                </DialogActions>
            </Dialog>
            {openLoadParams && <LoadParamsPopup onClose={() => setOpenLoadParams(false)} onSelect={loadParams} />}
        </Container>
    ) 
}
