import React, { useState } from 'react';
import styled from 'styled-components';
import ReactMde from 'react-mde';
import Showdown from "showdown";
import { FormattedMessage } from 'react-intl';

export const Container = styled.div.attrs({className: 'Container'})`
    width: 100%;
`;

const converter = new Showdown.Converter({
    tables: true,
    simplifiedAutoLink: true,
    strikethrough: true,
    tasklists: true
});

interface Props {
    value: string;
    setValue: (v: string) => void;
}

export const Markdown = ({value, setValue}: Props) => {
    const [selectedTab, setSelectedTab] = useState<"write" | "preview">("write");

    return <Container> 
        <ReactMde
            
            l18n={{
                write: <FormattedMessage id="formcontrol.markdown.write" />,
                preview: <FormattedMessage id="formcontrol.markdown.preview" />,
                uploadingImage: '',
                pasteDropSelect: ''
            }}
            value={value}
            onChange={setValue}
            selectedTab={selectedTab}
            onTabChange={setSelectedTab}
            generateMarkdownPreview={async markdown => await converter.makeHtml(markdown)}
        />
    </Container>
}

interface DisplayProps {
    text: string
}

export const MarkdownDisplay = ({text}: DisplayProps) => {
    return <div dangerouslySetInnerHTML={{__html: converter.makeHtml(text)}}></div>;
}