import React, { useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';
import { expandProps, FormControlProps } from './FormControlProps';
import NumberFormat from 'react-number-format';
import { useFormats } from './FormatsContext';

const DefaultPrecision = 2;

const nParseInt = (v: string) => {
    const parsed = Number.parseInt(v);
    return isNaN(parsed) ? null : parsed;
}

const nParseDecimal = (v: string) => {
    const parsed = Number.parseFloat(v);
    return isNaN(parsed) ? null : parsed;
}

interface NumberFormatCustomProps {
    inputRef: (instance: NumberFormat | null) => void;
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
    value: any;
}

function NumberFormatCustom(props: NumberFormatCustomProps) {
    const { inputRef, onChange, ...other } = props;
    const formats = useFormats();

    const thousandSeparator = formats.thousandSeparator as string;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator={!thousandSeparator || thousandSeparator === "" ? false : thousandSeparator}
            isNumericString
            onCopy={(e) => navigator.clipboard.writeText(other.value ? other.value.toString().replace(thousandSeparator, '') : '')}
        />
    );
}

export const NumberEdit = (props: FormControlProps) => {
    const { label, value, controlProps, field, row, onChange } = expandProps(props);

    return <TextField
        {...controlProps}
        label={label}
        value={value}
        onChange={e => onChange(row, { [field]: nParseInt(e.target.value) })}
        InputProps={{
            inputComponent: NumberFormatCustom as any
        }}
    />;
}

export const DecimalEdit = (props: FormControlProps) => {
    const { label, value, controlProps, onChange, field, row } = expandProps(props);
    const [error, setError] = useState<boolean>(false);
    const [innerValue, setInnerValue] = useState<string | null>('');

    const precision = props.extraProps?.precision || DefaultPrecision;

    useEffect(() => {
        setInnerValue(value ? nParseDecimal(value)?.toFixed(precision) : value);
    }, [value, precision]);

    const onLeave = () => {
        if (!innerValue) {
            onChange(row, { [field]: null });
            setError(false);
            return;
        }

        const parsed = nParseDecimal(innerValue);

        if (parsed) {
            setError(false);
            onChange(row, { [field]: innerValue });
            return;
        } else {
            onChange(row, { [field]: null });
            setError(true);
            return;
        }
    }

    return <TextField
        {...controlProps}
        label={label}
        value={innerValue}
        error={error}
        onChange={e => setInnerValue(e.target.value)}
        onBlur={onLeave}
        InputProps={{
            inputComponent: NumberFormatCustom as any
        }}
    />;
}
