import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
//@ts-ignore
import InspectModule from "docxtemplater/js/inspect-module";

export const getAllTags = (zip: PizZip) => {
    const ParamsModule = InspectModule();

    const docx = new Docxtemplater(zip, {modules: [ParamsModule]});

    docx.render();

    return ParamsModule.getAllTags();
}

const downloadFileBlob = (blob: Blob, filename: string) => {
    let anchor = document.createElement("a");
    document.body.appendChild(anchor);

    let objectUrl = window.URL.createObjectURL(blob);
            
    anchor.href = objectUrl;
    anchor.download = filename;
    anchor.click();

    window.URL.revokeObjectURL(objectUrl);
}

export const generateDocument = (file: File, data: any) => {
    const reader = new FileReader();

    reader.onload = e => {
        if (e.target && e.target.result) {
            const doc = new Docxtemplater().loadZip(new PizZip(e.target.result));

            doc.setData(data);
            doc.render()
            const blob = doc.getZip().generate({
                type: "blob",
                mimeType:
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            });
            

            downloadFileBlob(blob, file.name);
        } else {
            console.log("binary no loaded");
            
        }
    }

    reader.readAsBinaryString(file);
}

type LoadParamsAndValue = (
    file: File, 
    callback: (
        params: { [key: string]: any }, 
        value: any
    ) => void,
    error: () => void
) => void;

export const loadParamsAndValue: LoadParamsAndValue = (file, callback, error) => {
    const reader = new FileReader();

        reader.onload = e => {
            try {
                if (e.target && e.target.result) {
                    let params: any;

                    try {
                        params = getAllTags(new PizZip(e.target.result));;
                    } catch (err) {
                        error();
                        return;
                    }

                    const value: any = {};
                    
                    for (const key in params) {
                        if (Object.prototype.hasOwnProperty.call(params, key)) {
                            const keysOfParams = Object.keys(params[key])
                            value[key] = keysOfParams.length === 0 ? '' : [];
                        }
                    }

                    callback(params, value);

                } else {
                    console.log("binary no loaded");
                }
            } catch (err) {

            }
        }

        reader.readAsBinaryString(file);
}