export const rePlace = (re: string, replacement: string, flags: string, text: string) => {
    return text.replaceAll(new RegExp(re, flags+'g'), replacement);
}

export const headerLevelChange = (levelIn: string, levelOut: string, text: string) => {
    return text.replaceAll(new RegExp(`(<\\/?h)${levelIn}`, 'gi'), `$1${levelOut}`);
}

export const anchorCapture = (flags: string, text: string) => {
    let result = text;
    let start = 0;

    const eager = flags.includes("eager");

    while(start < result.length) {
        let aStarts = result.substring(start).toLowerCase().indexOf("<a");
        if(aStarts < 0) {
            return result;
        }
        aStarts = aStarts + start;

        let wrapperStarts = aStarts-1;
        if(wrapperStarts > 0 && !eager && result[wrapperStarts] !== '>') {
            start = aStarts+3;
            continue;
        }

        while(wrapperStarts > 0 && result[wrapperStarts] !== '<') {
            wrapperStarts--;
        }

        if(result[wrapperStarts] !== '<' || result[wrapperStarts+1] === '/') {
            start = aStarts+3;
            continue;
        }

        let wrapperName = '';
        wrapperStarts = wrapperStarts + 1;
        while(result[wrapperStarts] !== ' ' && result[wrapperStarts] !== '\t' && result[wrapperStarts] !== '>') {
            wrapperName += result[wrapperStarts];
            wrapperStarts++;
        }
        wrapperName = wrapperName.trim();
        if(!wrapperName.length) {
            start = aStarts+3;
            continue;
        }

        let aEnds = result.substring(aStarts).toLowerCase().indexOf("</a");
        if(aEnds < 0) {
            return result;
        }
        aEnds = aEnds + aStarts;

        let aEndsEnds = aEnds;
        while(aEndsEnds < result.length && result[aEndsEnds] !== '>') {
            aEndsEnds++;
        }
        if(aEndsEnds >= result.length) {
            return result;
        }

        let wrapperEnds = result.substring(aEndsEnds).toLowerCase().indexOf("</" + wrapperName);
        if(wrapperEnds < 0) {
            return result;
        }
        wrapperEnds = aEndsEnds + wrapperEnds;
        
        result = result.substring(0, aEnds) + result.substring(aEndsEnds+1, wrapperEnds) + "</a>" + result.substring(wrapperEnds);
        start = wrapperEnds;
    }

    return result;
}

export const injectTargetBlank = (text: string) => text.replaceAll(/href=/gi, 'target="_blank" rel="noopener noreferrer" href=');

export const injectIDFromName = (text: string) => text.replaceAll(/name='([^']+)'/gi, "id='$1' name='$1'")
    .replaceAll(/name="([^"]+)"/gi, 'id="$1" name="$1"');
