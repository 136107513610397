/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import styled from 'styled-components';
import { Header, Main } from '../Common';
import { DocxTemplater } from '../DocxTemplater/DocxTemplater';

export const Container = styled.div.attrs({className: 'Container'})`
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    font-family: sans-serif;
    font-size: 16px;
`;

export const Logo = styled.img.attrs({className: 'Logo', src: "/logo.png", alt: 'logo'})`
    width: 16px;
    height: 16px;
    margin: 0 3px;

    transform: scale(calc(18/16)) translateY(2px);
`;

export interface IUserRole {
    name: string;
    key: string;
}

export interface IDefaultUser {
    _id: string;
    email: string;
    invited_datetime?: string;
    last_login?: string;
    roles: string[];
    confirmed: boolean;
}

interface UserManagementFormProps<UserRole extends IUserRole> {
    title: string;
    userRoles: UserRole[];
    allowInvites: boolean;
}

export const MainPage = <UserRole extends IUserRole = IUserRole, IUser extends IDefaultUser = IDefaultUser>(props: UserManagementFormProps<UserRole>) => {
     return (
         <>
            <Header />
            <Main>
                <div className="description">
                    <h3>Docx document generator</h3>
                    <p>
                        This application generates docx documents by filling in parameter values in a template. You can use it to generate any kind of recurring documents.
                    </p>
                    <p>
                        <i>Note: the generation process happens in the browser, so neither your templates nor parameter values get sent over the network. We never see your information.</i>
                    </p>
                    <p>
                        To use the generator:
                        <ol>
                            <li>
                                Click the button below and select a template. Any docx document with parameters placeholders may serve as a template. To prepare your own document template, take a look at our <a download="example.docx" href="/example.docx">example</a>.
                            </li>
                            <li>Once the template is processed you will see a form for parameters - enter the desired values.</li>
                            <li>Click the <i>Generate document</i> button - it will download the document with the parameters filled in.</li>
                            <li>You're done. Rinse and repeat for a new document.</li>
                            <li>Note that you can process several documents at once - they will share the parameters (may be useful to generate a package of documents that use the same data).</li>
                            <li>If you have any questions, don't hesitate to contact us at <a href="mailto:desk@toolympus.com">desk@toolympus.com</a>.</li>
                        </ol>
                    </p>
                </div>
                <Container>
                    <DocxTemplater />
                </Container>
                <footer>
                    <p>Carefully crafted by <a href="https://toolympus.com" target="_blank">toOlympus.com</a></p>
                    <p>If you need a document generator of your own, do contact us at <br/><a href="https://toolympus.com"><img className="mini-logo" alt="logo" src="/logo.svg" /></a> <a href="mailto:desk@toolympus.com">desk@toolympus.com</a></p>
                    <p>Our infinite gratitude goes to the maintainers of the <a href="https://www.npmjs.com/package/docxtemplater" target="_blank">docxtemplater</a> library</p>
                </footer>
            </Main>
        </>
    )
}
