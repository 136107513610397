import { FieldSchema } from '../../hooks/useSchema';
import { OnFieldChange } from './Common';

export interface FormControlBaseProps {
    row: any;
    field: string;
    schema: FieldSchema;
}

export interface DisplayProps extends FormControlBaseProps {
    config?: {};
}

export interface FormControlProps extends FormControlBaseProps {
    extraProps?: any;
    onChange: OnFieldChange;
}

interface ExpandedProps extends FormControlProps {
    label: string;
    value: any;
    controlProps?: any;
    isVisible: boolean;
}

export const expandProps = (props: FormControlProps): ExpandedProps => {
    const { row, field, schema, extraProps } = props;

    const extraControlProps = extraProps?.disabled ? { disabled: true } : {};
    const inputProps = { maxLength: schema["max-length"], minLength: schema["min-length"], ...(extraProps?.controlProps || {}) };
    const controlProps = { margin: "dense", ...extraProps?.controlProps, view: {...extraProps || {}}, ...extraControlProps, inputProps: inputProps };

    return {
        ...props,
        value: row[field],
        label: extraProps?.label || schema?.label || "",
        controlProps: controlProps,
        isVisible: extraProps?.visibleIf ? extraProps.visibleIf(row) as boolean : true,
    }
}
