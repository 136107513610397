import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TableContainer, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import { FieldDefinition, TableForFields } from '../../toolympus/components/schemed';
import { Schema, FieldType } from '../../toolympus/hooks/useSchema';

interface CopyFromExcelPopupProps {
    data: {
        fields: FieldDefinition[],
        onClose: () => void;
        onFill: (data: any[]) => void;
    },
}

export const CopyFromExcelPopup = ({data: {fields, onClose, onFill}}: CopyFromExcelPopupProps) => {
    const [value, setValue] = useState('');

    const table: any[] = [];

    for (const row of value.split('\n')) {

        const columnObj: any = {};

        const splitRow = row.split('\t');

        for (const [id, [field]] of fields.entries()) {
            columnObj[field] = splitRow[id] || '';
        }

        table.push(columnObj);
    }

    table.pop() // при вставки из excel последний символ всегда "\n", по которому разделяются строки, поэтому в таблицу добавляется лишняя строка

    const schema: Schema = {};

    for (const [key] of fields) {
        schema[key] = {
            type: FieldType.text,
            label: key
        }
    }

    return (
        <Dialog open onClose={onClose}>
            <DialogTitle>Copy your table (without headers) from excel and paste it here</DialogTitle>
            <DialogContent>
                {table.length <= 1? 
                    <TextField multiline fullWidth autoFocus rows={6} value={value} onChange={e => setValue(e.target.value)} />
                    :
                    <TableContainer>
                        <Button onClick={() => setValue('')}>Clear</Button>
                        <TableForFields 
                            fields={fields.filter(([name]) => name !== 'remove-from-template')
                                .map(([name]) => [name])} // чтобы избавиться от { editable: true }
                            schema={schema}
                            data={table}
                        />
                    </TableContainer>
                    
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {onClose(); onFill(table);}}>Fill</Button>
            </DialogActions>
        </Dialog>
    );
}