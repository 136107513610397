import React, { ReactNode } from 'react';
import styled from 'styled-components/macro';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { Schema } from '../../hooks/useSchema';
import { FieldDefinition, OnFieldChange } from './Common';
import { Link } from '../primitives/Common.styles';
import { TableDisplay } from './TableDisplay';
import { TableEditor } from './TableEditor';
import { EmptyState } from '../primitives/EmptyState';

interface FormControlsForFieldsProps<RowType> {
    fields: FieldDefinition[];
    data: RowType[];
    schema: Schema;
    fieldLink?: (field: string) => ((row: RowType) => string) | null | undefined;
    fieldElement?: (field: string) => ((row: RowType) => (JSX.Element | string)) | null | undefined;
    onRowClick?: (row: RowType) => void;
    onDblClick?: (row: RowType) => void;
    selected?: (row: RowType) => boolean;
    rowButtons?: (row: RowType) => ReactNode;
    emptyState?: ReactNode;
    emptyStateText?: string | ReactNode;
    emptyStateClick?: () => void;
    onChange?: OnFieldChange;
}

const StyledTableCell = styled(TableCell)`
    & > * {
        min-width: 60%;
    }
`;

const TableRowNoSelect = styled(TableRow)`
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
`;

export const TableForFields = <RowType, >(props: FormControlsForFieldsProps<RowType>) => {
    const { fields, data, schema, onRowClick, onDblClick, selected, rowButtons, fieldLink, fieldElement, emptyStateText, emptyStateClick } = props;

    const getFieldLink = fieldLink || (() => () => null);
    const getElement = fieldElement || (() => () => null);
    const emptyState = props.emptyState || <EmptyState text={emptyStateText} onClick={emptyStateClick} />;

    const onChange = props.onChange || (() => { });

    return (
        <>
            <Table>
                <TableHead>
                    <TableRow>
                        {fields.map(([field]) => <TableCell key={field}>{schema[field]?.label}</TableCell>)}
                        {rowButtons && <TableCell />}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map(row => (
                        <TableRowNoSelect 
                            onClick={onRowClick ? () => onRowClick(row) : undefined}
                            onDoubleClick={onDblClick ? () => onDblClick(row) : undefined}
                            selected={selected? selected(row) : false}
                            >
                            {fields.map(([field, config]) => {
                                const link = (getFieldLink(field) || (() => null))(row);
                                const element = (getElement(field) || (() => null))(row);

                                const valueDisplay = <TableDisplay row={row} field={field} schema={schema[field]} />;
                                let cellContent = null;

                                if((config as any)?.editable) {
                                    cellContent = <TableEditor row={row} field={field} schema={schema[field]} onChange={onChange} />
                                } else if(link) {
                                    cellContent = <Link to={link}>{valueDisplay}</Link>;
                                } else if(element) {
                                    cellContent = element;
                                } else {
                                    cellContent = valueDisplay;
                                }
                                
                                const {width} = config || {};

                                return <StyledTableCell style={{width}} key={field}>{cellContent}</StyledTableCell>
                            })}
                            {rowButtons && (
                                <TableCell key="buttons" align="right">
                                    {rowButtons(row)}
                                </TableCell>
                            )}
                        </TableRowNoSelect>
                        ))}
                </TableBody>
            </Table>
            {(!data || data.length === 0) && emptyState}
        </>);
}
