export const ruMessages = {
    error: {
        general: "Произошло что-то нехорошее",
    },
    success: {
        clipboard: "Значение скопировано в буфер обмена"
    },
    login: {
        email: "Email",
        password: "Пароль",
        submit: "Войти"
    },
    signup: {
        errorMessage: "Мы не смогли вас зарегистрировать. Возможно срок действия приглашения истек или что-то еще пошло не так. Пожалуйста, свяжитесь с нами",
        successMessage: "Запрос отправлен. Вы получите доступ в систему, как только его утвердит администратор",
        email: "Email",
        password: "Пароль",
        passwordRepeat: "Повторите пароль",
        submit: "Зарегистрироваться"
    },
    signupConfirmation: {
        defaultMessage: "Подтверждаем email...",
        successMessage: "Email успешно подтвержден. Спасибо!",
        errorMessage: "Что-то пошло не так. Пожалуйста, свяжитесь с нами."
    },
    resetPassword: {
        email: "Email",
        submit: "Сбросить пароль"
    },
    restorePassword: {
        password: "Пароль",
        passwordRepeat: "Повторите пароль",
        submit: "Сменить пароль"
    },
    searchModal: {
        title: "Поиск",
        searchFieldLabel: "Найти...",
        pickItem: "Выбрать",
        emptyState: "Ничего не удалось найти. Попробуйте другой запрос."
    },
    userManagement: {
        title: "Управление пользователями",
        header: {
            addUser: "Добавить пользователя",
            sendInvites: "Отправить приглашения",
            sendInvintesTip: "Приглашения будут отправлены только новым пользователям, без повторов"
        },
        columns: {
            email: "Email",
            lastLogin: "Последний вход",
            invited: "Приглашен",
            confirmed: "Подтвержден",
            invite: "Пригласить",
            signInAs: "Авторизоваться"
        },
        userPopup: {
            title: "Добавить пользователей",
            email: "Email",
            submit: "Создать",
            hint: "укажите email'ы создаваемых пользователей через пробел или точку с запятой"
        }
    },
    emails: {
        popup: {
            title: "Информация",
            testEmail: "Отправить тестовое сообщение себе",
            testEmailTextTip: "Опционально используйте поле снизу для ввода email-параметров в формате JSON",
            send: "Отправить",
            delete: "Удалить",
            cancel: "Отменить",
            save: "Сохранить"
        }
    },
    dictionaries: {
        title: "Справочники",
        statusSaved: "Сохранено",
        newRecordLabel: "Код новой записи",
        addRecord: "Добавить",
        selectDictionary: "Справочник"
    },
    queryconsole: {
        title: "Название запроса",
        query: "Запрос",
        run: "Выполнить",
        history: "История",
    },
    common: {
        cancel: "Отмена"
    },
    formcontrol: {
        markdown: {
            write: 'Редактирование',
            preview: 'Предпросмотр',
        }
    },
    doxctemplater: {
        form: {
            fileNbr: 'Номер файла*',
            filePeriod: 'Период файла*',
            INVNUMBER: "INVNUMBER*",
            DATE: "Дата",
            PERIOD: "Период*",
            LINES: 'Строки',
            LINES_PRE: {
                title: 'Наименование работ',
                name: 'Навание',
                num: 'Кол-во',
                units: 'Ед. изм.',
                price: 'Цена',
                total: 'Итог',
            },
            LINES_NBR: "Количество строк*",
            PRICE: "Счёт*",
            TOTAL: "Итого*",
            TOTAL_WORDS: "Итого (словами)*",
            
            QTY_D: "QTY D*",
            DETAILS_PRE: {
                title: 'Описание работ',
                date: 'Дата',
                descript: 'Описание',
                time: 'Время, ч'
            },
            DETAILS: "Детали",
            button: 'Выбрать шаблон и генерировать'
        }
    }
}