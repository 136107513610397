import { Button, IconButton, TableContainer, TextField } from '@material-ui/core';
import { Add, Delete } from '@material-ui/icons';
import React, { useState } from 'react';
import { FieldDefinition, TableForFields } from '../../toolympus/components/schemed';
import { FieldType, Schema } from '../../toolympus/hooks/useSchema';
import { CopyFromExcelPopup } from './CopyFromExcelPopup';
import { Header } from './DocxTemplater.style';

interface ParamComponentProps {
    data: {
        param: any;
        title: string;
        value: any;
        setValue: (v: any) => void;
    },
}

export const ParamComponent = ({data: {param, title, value, setValue}}: ParamComponentProps) => {
    const [openCopyExcelPopup, setOpenCopyExcelPopup] = useState(false);
    const keys = Object.keys(param);

    if (keys.length === 0) {
        return <TextField fullWidth label={title} onChange={e => setValue(e.target.value)} value={value} />;
    }
    
    const schema: Schema = {};

    const fields: FieldDefinition[] = keys.map(key => [key, {editable: true}]);

    fields.push(['remove-from-template']);

    for (const key of keys) {
        schema[key] = {
            type: FieldType.text,
            label: key
        }
    }

    const data = (value as any[]).map((item: any, itemForDocxId: number) => ({...item, itemForDocxId}));

    const empty = () => {
        const obj: any = {};

        for (const key of keys) {
            obj[key] = '';
        }

        return obj;
    }

    return (
        <TableContainer>
            <Header>
                {title} ({value.length})
                <div>
                    <IconButton onClick={() => setValue([...value, empty()])}><Add /></IconButton>
                    <Button onClick={() => setOpenCopyExcelPopup(true)} variant="outlined">paste from excel</Button>
                </div>
            </Header>
            <TableForFields 
                fields={fields}
                schema={schema}
                data={data}
                onChange={(row, changes) => setValue(data.map((item: any, id: number) => item.itemForDocxId === row.itemForDocxId ? {...row, ...changes} : item))}
                
                fieldElement={field => 
                    field === 'remove-from-template'?
                        (row: any) => <IconButton onClick={() => 
                            setValue(data.filter((_: any, id: number) => id !== row.itemForDocxId))}><Delete /></IconButton>
                        :  
                        null
                }
            />
            {openCopyExcelPopup && 
                <CopyFromExcelPopup 
                    data={{
                        fields,
                        onClose: () => setOpenCopyExcelPopup(false),
                        onFill: pastedData => setValue([...pastedData, ...data]),
                    }} 
                />
            }
        </TableContainer>
    )
}