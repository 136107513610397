import axios from "axios";
import { apiFetch, FetchTypes, getApiTokenForRequest, UnauthenticatedError } from "./core";

export const apiUploadFile = (url: string, method: FetchTypes, formfield: string, file: any): Promise<any> => {
    const data = new FormData();
    data.append(formfield, file);
    return axios.request({
        url,
        method,
        data,
        headers: {
            "Authorization": getApiTokenForRequest(),
        },
        timeout: 120000,
    })
        .catch(error => {
            if (error.response.status === 401) {
                throw new UnauthenticatedError();
            } else {
                throw error;
            }
        });
}

export const apiFetchFile = (url: string) => apiFetch<Blob>(url, FetchTypes.GET, undefined, { responseType: 'blob' });

export const downloadFile = (url: string, filename: string) => {
    let anchor = document.createElement("a");
    document.body.appendChild(anchor);

    return apiFetchFile(url)
        .then(blob => {
            let objectUrl = window.URL.createObjectURL(blob);
            
            anchor.href = objectUrl;
            anchor.download = filename;
            anchor.click();

            window.URL.revokeObjectURL(objectUrl);
        })
}

/**
 * Колбэк для кнопок
 * @example
 * <Button onClick={callDownloadFile('/path/less/api', 'filename.file')}></Button>
 * 
 * @param url 
 * @param filename 
 */
export const callDownloadFile = (url: string, filename: string) => 
    () => downloadFile(url, filename);


/**
 * Открывает диалоговое окно для выбора файла и передаёт выбранный файл функции обратного вызова
 * @param callback 
 */
export const openFileUploader = (callback: (file: File) => void) => {
    const input = document.createElement('input') as HTMLInputElement;

    input.setAttribute('type', 'file');
    input.onchange = () => callback((input.files as FileList)[0]);
    input.click();
}

/**
 * Колбэк открывание окна для кнопок
 * Открывает диалоговое окно для выбора файла и передаёт выбранный файл функции обратного вызова
 * 
 * @example
 * <Button onClick={callOpenFileUploader(file => console.log(file.name))}></Button>
 * 
 * @param callback 
 */
export const callOpenFileUploader = (callback: (file: File) => void) =>
    () => openFileUploader(callback);

/**
 * Оборачивает файл в FormData
 * 
 * @example
 * (file: File) => apiFetch('/path', FetchTypes.POST, asFormData(file));
 * 
 * @param file 
 */
export const asFormData = (file: File, fieldName: string = 'file') => {
    const data = new FormData();
    data.set(fieldName, file);
    return data;
}

/**
 * Переводит файл в base64 код, 
 * который можно использовать как src для картинок и скачивания файлов
 * @example
 * // Этот пример больше для наглядности чем для применения
 * async (file: File) => <img src={await readBlobAsDataURL(file)}/>
 * 
 * @returns - Промис с получившимся base64 кодом
 */
export const readBlobAsDataURL = (blob: Blob): Promise<string | undefined> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            if (e.target) {
                resolve((e.target.result as string) || undefined);
            }
        }
        reader.readAsDataURL(blob);
    });
};