import { Card } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled(Card)`
    padding: 10px;
    margin: 0 auto;

    width: 100%;
    max-width: 800px;

    display: grid;
    gap: 10px;
`;

export const Field = styled.div.attrs({className: 'Field'})`
    border: 1px solid rgba(170, 170, 170, 0.5);
    padding: 10px;
`;

export const Header = styled.div.attrs({className: 'Header'})`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
