import React, { useState, useEffect, useContext, createContext, ComponentPropsWithoutRef } from 'react';
import { apiFetch, FetchTypes } from '../api/core';

export interface DictionaryRecord {
    code: string;
    label: string;
    disabled: boolean;
    comment: string;
    sortorder: number;
}

export interface Dictionary {
    id: string;
    name: string;
    comments: string;
    records: DictionaryRecord[];

    values?: { value: any, label: string }[];
    valueDict?: any;
}

export interface Dictionaries {
    [k: string]: Dictionary;
}

const prepareDictionary = (dictionary: Dictionary) => {
    const values = (dictionary.records || []).map(({ code, label }) => ({ value: code, label }));
    const valueDict = values.reduce((result, { value, label }) => ({ ...result, [value]: label}), {});

    return {
        ...dictionary,
        values,
        valueDict,
    }
}

export const DictionariesContext = createContext<Dictionaries>({});

export const DictionariesProvider = (props: ComponentPropsWithoutRef<any>) => {
    const [dicts, setDicts] = useState<Dictionaries>({});

    useEffect(() => {
        apiFetch<Dictionary[]>('/api/dictionary/all-with-records')
            .then(ds => {
                setDicts(Object.values(ds).reduce((result,d) => ({ ...result, [d.name]: prepareDictionary(d) }), {}))
            })
    }, []);

    const accessGuard = {
        get: (target: Dictionaries, name: string) => {
            const result = target[name];
            if(!result) {
                console.log(`Dictionary ${name} not loaded yet`);
                return prepareDictionary({} as Dictionary);
            } else {
                return result;
            }
        }
    };

    const guardedDicts = new Proxy(dicts, accessGuard);

    return (
        <DictionariesContext.Provider value={guardedDicts}>
            {props.children}
        </DictionariesContext.Provider>
    )
}

export const useDictionaries = (): Dictionaries => useContext(DictionariesContext);

export function useDictsApi() {
    const [dicts, setDicts] = useState<Dictionaries>({});
    const [loading, setLoading] = useState(false);
    const [dict, setCurrectDict] = useState<Dictionary | null>();
    const [newCodeRecord, setNewCodeRecord] = useState("");

    useEffect(() => {
        load();
    }, []);

    async function load() {
        setLoading(true);
        const data = await apiFetch<Dictionaries>('/api/dictionary/all-with-records');

        setDicts(data);
        setLoading(false);
    }

    async function add() {
        if (!dict) return;
        setLoading(true);

        const {sortorder: lastSortorder} = dict.records[dict.records.length - 1];

        const record: DictionaryRecord = {code: newCodeRecord, comment: '', label: '', sortorder: lastSortorder + 1, disabled: false };
        setNewCodeRecord("");

        const records = await apiFetch<DictionaryRecord[]>('/api/dictionary/' + dict.id, FetchTypes.POST, record);

        dict.records = records;
        setDicts({...dicts});
        setLoading(false);
    }

    function onUpdate(record: DictionaryRecord, changes: any) {
        if(!dict) return;
        const resRecod = {...record, ...changes};

        for (const [id, {code}] of dict.records.entries()) {
            if (code === resRecod.code) {
                dict.records[id] = resRecod;
            }
        }

        update(dict, resRecod)
    }

    async function update(dict: Dictionary, record: DictionaryRecord) {
        setLoading(true);
        setDicts({...dicts});

        const requestRecord: any = {...record};
        delete requestRecord.code;
        await apiFetch(`/api/dictionary/${dict.id}/${record.code}`, FetchTypes.PUT, requestRecord);
        setLoading(false);
    }

    function setDictByKey(key: string) {
        setCurrectDict(dicts[key])
    }

    return {
        newCodeRecord,
        loading, 
        dicts, 
        dict,
        add, 
        onUpdate,
        setDictByKey,
        setNewCodeRecord,
    }
}
