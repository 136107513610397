import React from 'react';
import { FormControl as MuiFormControl, FormHelperText, InputLabel, Input } from '@material-ui/core';
import {
    Checkbox,
    FormControlLabel,
} from '@material-ui/core';
import { FieldType } from '../../hooks/useSchema';
import {
    KeyboardDatePicker,
    KeyboardDateTimePicker
  } from '@material-ui/pickers';
import { expandProps, FormControlProps } from './FormControlProps';
import { Select, DictionarySelect } from './Select';
import { DecimalEdit, NumberEdit } from './NumberEdit';
import styled from 'styled-components/macro';
import { useFormats } from './FormatsContext';
import { Markdown } from './Markdown';

export const WrapperFlex = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-flow: row nowrap;

    & .MuiFormControl-marginDense {
        width: 100%;
    }
`;

const IconBox = styled.div`
    margin-left: 5px;
`;

const GetFormControl = (props: FormControlProps) => {
    const { row, field, schema, onChange, value, label, controlProps, isVisible } = expandProps(props);

    const formats = useFormats();

    if(!isVisible) {
        return <></>;
    }

    switch(schema?.type) {
        case FieldType.select:
            return <Select {...props} />;
        case FieldType.dictionarySelect:
            return <DictionarySelect {...props} />;
        case FieldType.bool:
            return <FormControlLabel
                    label={label}
                    control={<Checkbox
                                checked={value || false}
                                {...controlProps}
                                onChange={e => onChange(row, {[field]: e.target.checked})} />} />;

        case FieldType.date:
            return <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format={formats.date}
                        label={label}
                        value={value || null}
                        {...controlProps}
                        onChange={(date) => onChange(row, {[field]: date ? date.format('yyyy-MM-DD') : null})}
                        />;

        case FieldType.datetime:
            return <KeyboardDateTimePicker
                        variant="inline"
                        format={formats.datetime}
                        label={label}
                        value={value || null}
                        {...controlProps}
                        ampm={false}
                        onChange={(date) => onChange(row, {[field]: date? date.format('yyyy-MM-DD HH:mm:ss') : null})}
                        />;

        case FieldType.number:
            return <NumberEdit {...props} />
        case FieldType.decimal:
            return <DecimalEdit {...props} />
        case FieldType.markdown:
            return <Markdown value={value} setValue={value => onChange(row, {[field]: value})} />

        default:
            const { hint, disabled } = props.extraProps || {};
            const isLong = schema?.type === FieldType.textlong;
            const { rows } = controlProps.view;

            return <MuiFormControl fullWidth>
                <InputLabel>{label}</InputLabel>
                <Input
                    multiline={isLong}
                    rows={rows || 4}
                    value={value} 
                    onChange={({target: {value}}) => onChange(row, {[field]: value})} 
                    disabled={disabled}
                />
                {hint && <FormHelperText>{hint}</FormHelperText>}
            </MuiFormControl>;
    }
}

export const FormControl = (props: FormControlProps) => {
    const { row, field, controlProps } = expandProps(props);
    const { actions } = controlProps.view;

    return <WrapperFlex>
        <GetFormControl {...props} />
        {actions && <IconBox>
                {actions(row, field)}
            </IconBox>
        }
    </WrapperFlex>
}
