import React, { ReactNode, useContext } from 'react';
import moment, { MomentInput } from "moment";

export type ThousandSeparatorType = ',' | ' ' | ''

export interface Formats {
    date: string;
    datetime: string;
    thousandSeparator: ThousandSeparatorType
} 

const DefaultFormats = {
    date: "yyyy/MM/DD",
    datetime: "DD.MM.yyyy HH:mm",
    thousandSeparator: '' as ThousandSeparatorType
}

export const FormatsContext = React.createContext<Formats>(DefaultFormats);

interface FormatsX extends Formats {

}

export const useFormats = () => {
    const fmts = useContext(FormatsContext)

    return {
        ...fmts,
        formatDate: (v: MomentInput ) => (!v || v === "") ? "" : moment(v).format(fmts.date),
        formatDatetime: (v: MomentInput ) => (!v || v === "") ? "" : moment(v).format(fmts.datetime),
    }
};

interface ProviderProps {
    formats?: Formats;
    children: ReactNode;
}

export const FormatsProvider = (props: ProviderProps) => {
    return <FormatsContext.Provider value={props.formats || DefaultFormats}>
        {props.children}
    </FormatsContext.Provider>
}
