import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { Check, FileCopy } from '@material-ui/icons';
import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { Header, Main } from '../Common';
import { applyPipeline, preparePipeline } from './Pipeline';

interface Props {
    
}

const Container = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-row-gap: 12px;
    grid-column-gap: 12px;

    & > * {
        width: 100%;
    }
`;

const AreaWithControls = styled.div`

    & > textarea {
        width: 100%;
        min-height: 300px;
        box-sizing: border-box;
    }
`;

const DefaultPipeline = `strip-harness
strip-comments
strip-tag-whitespace
strip-spans
strip-conditionals
#process-mso-list ol
#strip-list-tokens
strip-styles
strip-classes
strip-nbsp
strip-linebreaks-in-p h4
strip-empty-tag keep-ws
strip-empty-tag keep-ws
strip-empty-tag keep-ws
strip-excess-empty-lines
#header-level-change 3 4
#re-place (<\\/?h)3 ... $14
#anchor-capture eager
#inject-target-blank
#inject-id-from-name
#tsv-to md
`;

const Notes_LS_Key = "dox_tp_notes";

export const TextProcessorPage = (props: Props) => {
    const [src, setSrc] = useState<string>("");
    const [result, setResult] = useState<string>("");
    const [pipeline, setPipeline] = useState<string>(DefaultPipeline);

    const [isNotesOpen, setIsNotesOpen] = useState<boolean>(false);
    const [notes, setNotes] = useState<string>(localStorage.getItem(Notes_LS_Key) || "");

    const [isPasteHtml, setIsPasteHtml] = useState<boolean>(true);

    const updateNotes = (v: string) => {
        setNotes(v);
        localStorage.setItem(Notes_LS_Key, v);
    }

    const handlePaste: React.ClipboardEventHandler<HTMLTextAreaElement> = e => {
        e.preventDefault();
        e.stopPropagation();

        if (isPasteHtml && e && e.clipboardData && e.clipboardData.types && e.clipboardData.types.includes("text/html")) {
            const v = e.clipboardData.getData("text/html");
            setSrc(v);
            setResult(v);
        } else {
            const v = e.clipboardData.getData("text/plain");
            setSrc(v);
            setResult(v);
        }
    }

    const applyPipelineX = () => {
        const pl = preparePipeline(pipeline);
        setResult(applyPipeline(pl, src));
    }

    const copyResult = () => {
        navigator.clipboard.writeText(result);
    }

    return (
        <>
            <Header />
            <Main pad="small">
                <Container>
                    <AreaWithControls>
                        <textarea onPaste={handlePaste} value={src} onChange={e => setSrc(e.target.value)} />
                    </AreaWithControls>

                    <AreaWithControls>
                        <textarea value={pipeline} onChange={e => setPipeline(e.target.value)} />
                    </AreaWithControls>

                    <AreaWithControls>
                        <textarea value={result} onChange={() => {}} />
                    </AreaWithControls>

                    <AreaWithControls>
                        <Button color="primary" variant="contained" onClick={applyPipelineX}>apply</Button>
                        <Button color="primary" startIcon={<FileCopy />} onClick={copyResult}>copy result</Button>

                        <div>
                            <Button color="primary" startIcon={<FileCopy />} onClick={() => setIsNotesOpen(true)}>notes</Button>

                        </div>

                        <div>
                            <Button size="small" color="primary" variant={isPasteHtml ? "contained" : "outlined"} startIcon={isPasteHtml && <Check />} onClick={() => setIsPasteHtml(x => !x)}>paste html</Button>
                            <Button size="small" color="primary" variant={!isPasteHtml ? "contained" : "outlined"} startIcon={!isPasteHtml && <Check />} onClick={() => setIsPasteHtml(x => !x)}>paste text</Button>
                        </div>
                    </AreaWithControls>
                </Container>

                <Dialog open={isNotesOpen} onClose={() => setIsNotesOpen(false)} fullWidth maxWidth="lg">
                    <DialogTitle>Notes</DialogTitle>

                    <DialogContent>
                        <textarea
                            style={{ width: "100%", boxSizing: "border-box", height: "100%", minHeight: "400px" }}
                            value={notes}
                            onChange={e => updateNotes(e.target.value)}
                            />
                    </DialogContent>
                    <DialogActions><Button onClick={() => setIsNotesOpen(false)}>close</Button></DialogActions>
                </Dialog>
            </Main>
        </>
    );
}
