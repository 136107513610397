import { AppBar, Button, Toolbar } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

interface Props {
    
}

const ULink = styled(Link)`
    text-decoration: none;
    color: inherit;
`;

const Logo = styled.img`
    width: 16px;
    height: 16px;
    margin-right: 16px;

    transform: scale(calc(18/16));
    filter: brightness(100);
`;

export const Header = (props: Props) => {
    return (
        <AppBar>
            <Toolbar>
                <Logo className="mini-logo" alt="logo" src="/logo.svg" />
                <ULink to="/"><Button color="inherit">Generator</Button></ULink>
                <ULink to="/processor"><Button color="inherit">Text processor</Button></ULink>
            </Toolbar>
        </AppBar>
    );
}
