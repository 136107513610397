import { stripClasses, stripComments, stripConditionals, stripEmptyTag, stripExcessEmptyLines, stripHarness, stripLinebreaksInP, stripNbsp, stripSpans, stripStyles, stripTagWhitespace } from "./HarnessSteps";
import { processTsv } from "./TablesSteps";
import { processMSOList, stripListTokens } from "./TransformListSteps";
import { anchorCapture, headerLevelChange, injectIDFromName, injectTargetBlank, rePlace } from "./TransformSteps";

export type PipelineStep = (v: string) => string;
export type PipelineStepCreator = (descriptor: string) => PipelineStep;


export const EmptyStep: PipelineStep = (v => { console.log('empty step'); return v; });

const StepsFactory: Record<string, PipelineStepCreator> = {
    'strip-harness': () => stripHarness,
    'strip-comments': () => stripComments,
    'strip-styles': () => stripStyles,
    'strip-classes': () => stripClasses,
    'strip-tag-whitespace': () => stripTagWhitespace,
    'strip-empty-tag': d => t=> stripEmptyTag(d,t),
    'strip-spans': () => stripSpans,
    'strip-nbsp': () => stripNbsp,
    'strip-linebreaks-in-p': d => t => stripLinebreaksInP(d,t),
    'strip-conditionals': () => stripConditionals,
    'strip-excess-empty-lines': () => stripExcessEmptyLines,

    'header-level-change': d => {
        const [levelIn, levelOut] = d.split(" ").map(s => s.trim());
        return text => headerLevelChange(levelIn, levelOut, text);
    },

    're-place': d => {
        const sep = d.indexOf("...");
        const re = d.substring(0, sep).trim();
        const placement = d.substring(sep+4);
        return text => rePlace(re, placement, "i", text);
    },

    're-place-cs': d => {
        const sep = d.indexOf("...");
        const re = d.substring(0, sep).trim();
        const placement = d.substring(sep+4);
        return text => rePlace(re, placement, "", text);
    },

    'anchor-capture': d => (t => anchorCapture(d, t)),
    'inject-target-blank': () => injectTargetBlank,
    'inject-id-from-name': () => injectIDFromName,

    'process-mso-list': d => t => processMSOList((d || "").trim().length ? d.trim() : "ul", t),
    'strip-list-tokens': () => stripListTokens,

    'tsv-to': d => t => processTsv((d || "").trim(), t),
}

export const createStep = (type: string, descriptor: string): PipelineStep => {
    const creator = StepsFactory[type];

    if(creator) {
        return creator(descriptor.trim());
    } else {
        console.log(`Not found: ${type}`);
        return EmptyStep;
    }
}
