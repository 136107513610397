import React from 'react';
import {
    InputLabel,
    MenuItem,
    Select as SelectMUI,
    FormControl as FormControlMaterial,
    FormHelperText,
} from '@material-ui/core';
import { expandProps, FormControlBaseProps, FormControlProps, DisplayProps } from './FormControlProps';
import { Dictionaries, useDictionaries } from '../../hooks/useDictionaries';

export const Select = (props: FormControlProps) => {
    const { field, row, label, value, schema, controlProps, extraProps, onChange } = expandProps(props);

    const showValues = extraProps?.showValues;

    return (
        <FormControlMaterial style={{width: '100%'}} margin="dense">
            <InputLabel>{label}</InputLabel>
            <SelectMUI
                value={value || ""}
                {...controlProps}
                onChange={e => onChange(row, { [field]: e.target.value })}>
                {(schema.values || []).concat([{ value: null, label: "-"}]).map(({ value, label}) => (
                    <MenuItem key={value || 'undef'} value={value}>{showValues ? value : label}</MenuItem>
                ))}
            </SelectMUI>
            {extraProps && extraProps.hint && <FormHelperText>{extraProps.hint}</FormHelperText>}
        </FormControlMaterial>);
}

const adjustDictionarySelectProps = <T extends FormControlBaseProps>(props: T, dictionaries: Dictionaries): T => {
    const { schema } = props;
    const thisDict = dictionaries[schema?.dictionary || ""];

    const newSchema = { ...schema, values: thisDict.values, valueDict: thisDict.valueDict };

    return { ...props, schema: newSchema };
}

export const DictionarySelect = (props: FormControlProps) => {
    const dictionaries = useDictionaries();
    return <Select {...adjustDictionarySelectProps(props, dictionaries)} />
}

export const SelectDisplay = ({ schema, row, field }: DisplayProps) => {
    const value = row[field];
    return <>{schema.valueDict[value] || value}</>;
}

export const DictionarySelectDisplay = (props: DisplayProps) => {
    const dictionaries = useDictionaries();
    return <SelectDisplay {...adjustDictionarySelectProps(props, dictionaries)} />    
}