import { createStep, PipelineStep } from "./PipelineSteps";


export const preparePipeline = (pipeline: string): PipelineStep[] => {
    const active = pipeline.split('\n').map(s => s.trim()).filter(s => !!s.length && !s.startsWith('#'));
    return active.map(s => {
        const [step_type] = s.split(' ');
        return createStep(step_type, s.substring(step_type.length));
    })
}

export const applyPipeline = (pipeline: PipelineStep[], input: string): string => {
    return pipeline.reduce((r,step) => step(r), input);
}