const findNextTagStart = (text: string, from: number) => {
    return text.indexOf('<', from);
}

const grabTagName = (text: string, position: number) => text.substring(position, position+100).split(/\s+|>/)[0].toLowerCase().replace("/", "");

export const processMSOList = (listtype: string, text: string) => {
    const [ItemTag, CloseItemTag, ListTag, CloseListTag] = ["<li>", "</li>", `<${listtype}>`, `</${listtype}>`];
    let result = text;
    let start = 0;

    const stack: any[] = [];
    const stackSnapshot = () => stack.join("->");
    const stackSnapshotPre = () => stack.slice(0,-1).join("->");

    const levels: any[] = [];

    const liStarts: Record<string,string|null> = {};

    while(start < result.length) {
        const tagStart = findNextTagStart(result, start);
        const tagName = grabTagName(result, tagStart);
        console.log(tagStart, tagName);
        if(tagStart < 0) {
            break;
        }

        if(result[tagStart+1] === "/") {
            stack.pop();
            start = tagStart+1;
            continue;
        } else {
            stack.push(tagName);
        }

        const match = new RegExp("^[^>]*mso-list:\\s*(l\\d+\\s+level\\d+)").exec(result.substring(tagStart));
        if(!match) {
            const toInjectItems = [];
            
            while(levels.length && stackSnapshotPre() === liStarts[levels[0]]) {
                toInjectItems.push(CloseItemTag, CloseListTag);
                liStarts[levels[0]] = null;
                levels.shift();
            }

            if(toInjectItems.length) {
                const toInject = "\n" + toInjectItems.join("\n") + "\n";
                const injectionPoint = tagStart;
                result = result.substring(0,injectionPoint) + toInject + result.substring(injectionPoint);
                start = injectionPoint + toInject.length+1;
            } else {
                start = tagStart+1;
            }
            continue;
        }

        const level = match[1];
        const toInjectItems = [ItemTag];

        if(!levels.length) {
            levels.unshift(level);
            toInjectItems.unshift(ListTag);
        } else if (levels[0] < level) {
            levels.unshift(level);
            toInjectItems.unshift(ListTag);
        } else if (levels[0] > level) {
            // same level prev tag close
            toInjectItems.unshift(CloseItemTag);

            while(levels[0] > level) {
                toInjectItems.unshift(CloseItemTag, CloseListTag);
                liStarts[levels[0]] = null;
                levels.shift();
            }
        } else {
            toInjectItems.unshift(CloseItemTag);
        }

        const toInject = "\n" + toInjectItems.join("\n") + "\n";
        liStarts[level] = stackSnapshotPre();

        result = result.substring(0, tagStart) + toInject + result.substring(tagStart);
        start = tagStart+1+toInject.length;
    }

    const toInjectItems = [];
    while(levels.length && stackSnapshot() === liStarts[levels[0]]) {
        toInjectItems.push(CloseItemTag, CloseListTag);
        liStarts[levels[0]] = null;
        levels.shift();
    }
    // unwind levels here
    return result + "\n" + toInjectItems.join("\n") + "\n";
}


export const stripListTokens = (text: string) => text.replaceAll(/(<li[^/]*>)\s*(\d+|[-+•])[.)]?\s*/gi, "$1");
