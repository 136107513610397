export const stripHarness = (text: string) => {
    const noBodyHtml = text.replaceAll(/<\/?html[^>]*>/gi, "").replaceAll(/<\/?body[^>]*>/gi, "");
    const headStarts = noBodyHtml.toLowerCase().indexOf("<head");
    const headEnds = noBodyHtml.toLowerCase().indexOf("</head");
    if(headStarts >= 0 && headEnds >= 0) {
        const preHead = noBodyHtml.substring(0, headStarts);
        const postHead = noBodyHtml.substring(headEnds);
        const headCloseEnds = postHead.indexOf('>');

        return preHead + postHead.substring(headCloseEnds+1);
    } else {
        return noBodyHtml;
    }
    
};

export const stripStyles = (text: string) => text.replaceAll(/style='[^']*'/gi, "").replaceAll(/style="[^"]*"/gi, "");
export const stripClasses = (text: string) => text.replaceAll(/class='[^']*'/gi, "").replaceAll(/class="[^"]*"/gi, "").replaceAll(/class=\w+/gi, "");

export const stripSpans = (text: string) => text.replaceAll(/<\/?\s*span[^>]*>/gi, "");
export const stripConditionals = (text: string) => text.replaceAll(/<!\[[^>]+>/gi, '');
export const stripNbsp = (text: string) => text.replaceAll(/&nbsp;/gi, ' ');

export const stripComments = (text: string) => {
    let result = text;
    while(result.includes('<!--') && result.includes('-->')) {
        result = result.substring(0, result.indexOf('<!--')) + result.substring(result.indexOf('-->')+3);
    }
    return result;
};

export const stripTagWhitespace = (text: string) => text.replaceAll(/\s+>/gi, '>').replaceAll(/(<\/?)\s+/gi, '$1');

export const stripEmptyTag = (flags: string, text: string) => text.replaceAll(/<[^/][^>]*>(\s*)<\/[^>]+>/gi, flags.includes('keep-ws') ? '$1' : '');

export const stripLinebreaksInP = (tag: string, text: string) => {
    const tagX = (tag || "").trim().length ? tag.trim() : "p";
    let result = text;
    let start = 0;
    while(start < result.length) {
        let pStart = result.toLowerCase().substring(start).indexOf(`<${tagX}`);
        if(pStart < 0) {
            return result;
        }
        pStart = pStart + start;

        let pEnd = result.toLowerCase().substring(pStart).indexOf(`</${tagX}`);
        if(pEnd < 0) {
            return result;
        }
        pEnd = pEnd + pStart;

        result = result.substring(0, pStart) + result.substring(pStart, pEnd).replaceAll("\r", "").replaceAll("\n", " ") + result.substring(pEnd);
        start = pStart + 3;
    }
    return result;
};

export const stripExcessEmptyLines = (text: string) => {
    return text.replaceAll(/^[^\S\r\n]+$/gm, "").replaceAll(/\r/g, "").replaceAll(/(\n\n)(\n)+/gm, "$1");
}