import React from 'react';
import { Checkbox } from '@material-ui/core';
import { FieldType } from '../../hooks/useSchema';
import { DisplayProps } from './FormControlProps';
import { SelectDisplay, DictionarySelectDisplay } from './Select';
import { useFormats } from './FormatsContext';
import { MarkdownDisplay } from './Markdown';

export const TableDisplay = (props: DisplayProps) => {
    const { row, field, schema } = props;

    const { formatDate, formatDatetime } = useFormats();

    const value = row[field];

    switch (schema.type) {
        case FieldType.bool:
            return <Checkbox disabled checked={row[field]} />;
        case FieldType.select:
            return <SelectDisplay {...props} />;
        case FieldType.dictionarySelect:
            return <DictionarySelectDisplay {...props} />;
        case FieldType.date:
            return <>{formatDate(value)}</>;
        case FieldType.datetime:
            return <>{formatDatetime(value)}</>;
        case FieldType.markdown:
            return <MarkdownDisplay text={value} />;
        default:
            return <>{value}</>;
    }
};
